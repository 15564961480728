import { combineReducers, configureStore } from '@reduxjs/toolkit';

import globalReducer from './global/reducer';
import startPageReducer from './startpage/reducer';
import productPageReducer from './productpage/reducer';
import cartReducer from './cart/reducer';
import shoppingCartReducer from './shoppingcart/reducer';

// import storage from 'redux-persist/lib/storage';
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    global: globalReducer,
    startpage: startPageReducer,
    productpage: productPageReducer,
    cart: cartReducer,
    shoppingcart: shoppingCartReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store)